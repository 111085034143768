



























































import Vue from 'vue';
import CreateMembershipModel from '@/src/services/viewModel/resource/CreateMembershipRequestViewModel';
import CalculateMembershipRequestViewModel from '@/src/services/viewModel/request/Memberships/CalculateMembershipRequestViewModel';
import CreateMembershipBasketModel from '../../../../../services/viewModel/resource/BasketResourceViewModel';
import LocationCitySearchResourceViewModel from '@/src/services/viewModel/resource/LocationCitySearchResourceViewModel';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    isLoading: false,
    disablePostcodeLookup: false,
    errorResponse: undefined,
    payrollSelectData: [] as any[],
    doesPostalcodeExist: true,
  }),
  computed: {
    isBasketEmpty(): boolean {
      const items = this.innerValue?.orderBasket?.items ?? [];
      return items.length === 0;
    },
    canCalculateCost(): boolean {
      const hasPostalCode = !!this.innerValue.memberBusinessContact?.address.postalCode;
      const hasPayrollCategory = !!this.innerValue.payrollCategoryId;
      return hasPostalCode && hasPayrollCategory;
    },
    innerValue: {
      get(): CreateMembershipModel {
        return this.value ?? {};
      },
      set(value: CreateMembershipModel) {
        this.$emit('input', value);
      },
    },
  },
  async beforeMount() {
    this.isLoading = true;
    const response = await this.$service.api.memberships.getPayrollLookup();
    this.payrollSelectData = response.items;
    this.isLoading = false;
  },
  methods: {
    resetCosts() {
      if (this.innerValue.orderBasket) {
        this.$set(this.innerValue.orderBasket, 'items', []);
        this.innerValue.orderBasket.items = [] as any;
      } else {
        this.$set(this.innerValue, 'orderBasket', undefined);
        this.innerValue.orderBasket = undefined as any;
      }
    },
    onCitySelected(locationModel: LocationCitySearchResourceViewModel) {
      this.innerValue.memberBusinessContact.address.city = locationModel.city;
    },
    async getCalculatedCost() {
      this.doesPostalcodeExist = true;
      this.isLoading = true;
      this.errorResponse = undefined;
      this.resetCosts();
      const postalCode = this.innerValue.memberBusinessContact?.address.postalCode;
      try {
        const businessUnit = await this.$service.api.businessUnits.getBusinessUnitByPostalCode(postalCode!);
        this.innerValue.additionalInformationRequired = businessUnit.isMembershipAdditionalInfoRequired;
        this.innerValue.onlinePaymentAllowed = businessUnit.isMembershipOnlinePaymentAllowed;
        this.innerValue.isDirectRegistration = businessUnit.isMembershipDirectRegistration;
        this.innerValue.businessUnitId = businessUnit.id!;

        const businessUnitId = businessUnit.id;
        const categoryId = this.innerValue.payrollCategoryId;
        const options = new CalculateMembershipRequestViewModel().fromModel({
          isMembershipReEntry: this.innerValue.isMembershipReEntry,
          postalCode: postalCode!,
        });
        const costs = await this.$service.api.memberships.calculateMembership(businessUnitId!, categoryId, options);
        this.innerValue.membershipTemplateId = costs.membershipTemplateId!;

        this.innerValue.orderBasket = new CreateMembershipBasketModel().fromModel(this.innerValue.orderBasket);
        this.innerValue.orderBasket.currency = costs.currency as any;
        this.innerValue.orderBasket.items = costs.items ?? [];
        this.innerValue.orderBasket.paymentMethod = 1;
        this.innerValue.orderBasket.membershipTemplateId = costs.membershipTemplateId!;
        // FIX to calculate basket, below items are needed
        this.innerValue.orderBasket.businessUnitNumber = businessUnit.number;
        this.innerValue.orderBasket.businessPostalCode = postalCode!;
        this.innerValue.orderBasket.membershipCategoryId = categoryId;
        // FIX End
      } catch (error: any) {
        if (error && error.status === 404) {
          this.doesPostalcodeExist = false;
        } else {
          this.errorResponse = error;
        }
      }
      this.isLoading = false;
    },
  },
});
